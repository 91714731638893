import gql from 'graphql-tag';
export const GET_DETAILED_NODE = gql`
query GetMyStudPortfolios($input: GetManyStudentPortfoliostInput) {
  getMyStudPortfolios(input: $input) {
    currentPage
    data {
      id
      uuid
      name
      display
      socialLinks
      description
      logo
      bannerImage
      state
      email
      credentials
      userGroup
      feedback
      recommendation
      goal
      createdAt
      updatedAt
    }
    pageSize
    totalCount
    totalPages
  }
}
`;